@use '@typography' as *;
@use '@queries' as *;

.breadcrumbs {
  display: flex;
  align-items: center;
  width: max-content;
  gap: 8px;

  min-height: 24px;

  &-separator {
    color: var(--cst-bread-crumbs-symbol);
    @include typography-s;
    @include weight-medium;

    @include min-1440-break {
      @include typography-base;
    }
  }

  &-item {
    position: relative;
    color: var(--cst-bread-crumbs-title-default);

    @include typography-base;
    @include weight-medium;

    @include min-1440-break {
      @include typography-m;
    }

    &:hover {
      &:after {
        position: absolute;
        content: '';
        display: flex;
        right: 0;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background: var(--cst-bread-crumbs-title-default);
      }
    }

    &-active {
      color: var(--cst-bread-crumbs-title-active) !important;
      &:hover {
        &:after {
          background: var(--cst-bread-crumbs-title-active) !important;
        }
      }
    }
  }
}
